<template>
  <common-modal :id="dialogId" title="Jiraヘルパー " size="lg" :buttons="buttons">
    <div class="JiraHelp">
      <div class="JiraHelp__content">
        <p>APIトークンを作成するために、以下のリンクにアクセスします。</p>
        <p>https://id.atlassian.com/manage-profile/security/api-tokens</p>
      </div>
      <div class="JiraHelp__image">
        <img src="@/assets/images/jira_help.svg" alt="image-help" />
      </div>
    </div>
  </common-modal>
</template>

<script>
import CommonModal from '@/components/commons/common-modal'
export default {
  name: 'JiraHelpModal',
  components: { CommonModal },
  props: {
    dialogId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      buttons: [{ title: '戻る', variant: 'light', minWidth: 74, size: 'md' }],
    }
  },
}
</script>

<style lang="scss">
.JiraHelp {
  &__content {
    margin-top: 20px;
    p {
      margin: 2px 0;
    }
  }
  &__image {
    img {
      display: block;
      margin: auto;
      width: 75%;
    }
  }
}
</style>
