<template>
  <div>
    <common-modal
      :id="dialogId"
      title="JIRAに連携"
      size="lg"
      :buttons="buttons"
      :handle-hide-modal="resetFormValue"
    >
      <div class="JiraConnection">
        <b-form>
          <div class="JiraConnection__logo">
            <img :src="logo || defaultCompanyLogo" alt="jira-logo" />
          </div>
          <div class="JiraConnection_form">
            <b-form-group id="input-group-1" label="ドメイン" label-for="input-1" class="required">
              <b-input-group>
                <b-form-input
                  id="input-1"
                  :value="form.domain"
                  type="text"
                  :class="status($v.form.domain)"
                  @change="setFormValue('domain', $event)"
                />
                <b-input-group-append v-if="!!errorMessages.form.domain" class="icon-alert">
                  <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
                </b-input-group-append>
              </b-input-group>
              <span v-if="!!errorMessages.form.domain" class="label-error"
                >{{ errorMessages.form.domain }}
              </span>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="メールアドレス"
              label-for="input-2"
              class="required"
            >
              <b-input-group>
                <b-form-input
                  id="input-2"
                  :value="form.email"
                  type="text"
                  :class="status($v.form.email)"
                  @change="setFormValue('email', $event)"
                />
                <b-input-group-append v-if="!!errorMessages.form.email" class="icon-alert">
                  <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
                </b-input-group-append>
              </b-input-group>
              <span v-if="!!errorMessages.form.email" class="label-error"
                >{{ errorMessages.form.email }}
              </span>
            </b-form-group>

            <b-form-group id="input-group-3" label-for="input-3" class="required">
              <label>APIトークン</label>
              <img
                src="@/assets/icons/ic_help.svg"
                alt="edit-icon"
                class="icon"
                @click="showHelpModel"
              />
              <b-input-group>
                <b-form-input
                  id="input-3"
                  :value="form.token"
                  type="text"
                  :class="status($v.form.token)"
                  @change="setFormValue('token', $event)"
                />
                <b-input-group-append v-if="!!errorMessages.form.token" class="icon-alert">
                  <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
                </b-input-group-append>
              </b-input-group>
              <span v-if="!!errorMessages.form.token" class="label-error"
                >{{ errorMessages.form.token }}
              </span>
            </b-form-group>
          </div>
        </b-form>
      </div>
    </common-modal>
    <jira-help-modal :dialog-id="dialogHelp" />
  </div>
</template>

<script>
import CommonModal from '@/components/commons/common-modal'
import { DEFAULT_COMPANY_LOGO } from '@/utils/constants'
import { MESSAGES } from '@/utils/messages'
import { email, required } from 'vuelidate/lib/validators'
import JiraHelpModal from '@/components/modals/jira-help-modal'
export default {
  name: 'JiraConnectionModal',
  components: { JiraHelpModal, CommonModal },
  props: {
    dialogId: {
      type: String,
      required: true,
    },
    logo: {
      type: String,
      default: '',
    },
    formData: {
      type: Object,
      default: null,
    },
    buttons: {
      type: Array,
      default: null,
    },
    triggerValidate: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultCompanyLogo: DEFAULT_COMPANY_LOGO,
      form: {},
      errorMessages: {
        form: {
          domain: '',
          email: '',
          token: '',
        },
      },
      dialogHelp: 'dialogHelp',
    }
  },
  validations: {
    form: {
      domain: {
        required,
      },
      email: {
        required,
        email,
      },
      token: {
        required,
      },
    },
  },
  computed: {
    defaultFormData() {
      return { domain: '', email: '', token: '' }
    },
  },
  watch: {
    form(newValue) {
      this.$emit('update:formData', newValue)
    },
    triggerValidate(newVal) {
      this.$emit('update:isValid', this.isValidForm())
    },
  },
  mounted() {
    this.form = { ...this.defaultFormData }
    if (this.formData) {
      this.form = { ...this.form, ...this.formData }
    }
  },
  methods: {
    showHelpModel() {
      this.$bvModal.show(this.dialogHelp)
    },
    setValidateErrorMessage(instance, name) {
      // check required
      if (!instance.required) {
        this.errorMessages.form[name] = MESSAGES.COMMON.MSG08
      } else {
        this.errorMessages.form[name] = ''
      }
      this.checkEmail(instance, name)
    },
    setFormValue(name, value) {
      if (value === undefined) {
        return
      }
      this.form[name] = value
      const validateInstance = this.$v.form[name]
      validateInstance.$touch()
      this.setValidateErrorMessage(validateInstance, name)
    },
    status(validation) {
      return {
        invalid: validation.$dirty && validation.$invalid,
      }
    },
    checkEmail(instance, name) {
      if (name === 'email') {
        if (!instance.email) {
          this.errorMessages.form[name] = MESSAGES.COMMON.MSG09
        }
      } else {
        if (!this.errorMessages.form[name]) {
          this.errorMessages.form[name] = ''
        }
      }
    },

    isValidForm() {
      this.$v.form.$touch()
      const isValid = !this.$v.form.$invalid
      if (!isValid) {
        Object.keys(this.errorMessages.form).forEach((key) => {
          this.setValidateErrorMessage(this.$v.form[key], key)
        })
      }
      return isValid
    },
    resetFormValue() {
      this.$v.form.$reset()
      this.form = { ...this.defaultFormData }
      Object.keys(this.errorMessages.form).forEach((key) => {
        this.errorMessages.form[key] = ''
      })
    },
  },
}
</script>

<style lang="scss">
.JiraConnection {
  &__logo {
    margin: 20px auto;
    height: 70px;
    width: 70px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .required {
    label:after {
      content: '*';
      color: red;
    }
  }
  .icon {
    margin: 0 10px;
    cursor: pointer;
  }
}
</style>
