import axiosEx from '@/configs/http/external-api-axios'
import axiosIn from '@/configs/http/internal-api-axios'
import { CORS_EVERY_WHERE_URL, STATUS_CODE } from '@/utils/constants'
import get from 'lodash-es/get'
import { MESSAGES } from '@/utils/messages'

async function getCloudId(domain) {
  const url = `${CORS_EVERY_WHERE_URL}https://${domain}.atlassian.net/_edge/tenant_info`
  const response = await axiosEx
    .get(url)
    .then((res) => {
      return res
    })
    .catch(() => {
      return false
    })
  if (!response) {
    return response
  }
  return response.data.cloudId
}

async function fetchListUser(subscribe_apps_id) {
  if (!subscribe_apps_id) {
    return
  }
  const url = `api/v1/service/jira/${subscribe_apps_id}`
  const response = await axiosIn
    .get(url)
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err
    })
  return response.data
}

function getDialogState() {
  /*
  true function can be used
  */
  return {
    ADD: false,
    CREATE: true,
    INVITE: false,
    DELETE: true,
    FETCH: true,
  }
}

async function addUser(subscribe_apps_id, userIds) {
  if (subscribe_apps_id) {
    const url = `api/v1/service/jira/${subscribe_apps_id}/add`
    return axiosIn.post(url, { user_id: userIds.toString() }).then((res) => {
      return res.data.success
    })
  }
  return false
}

async function deleteUser(subscribe_apps_id, userIds) {
  if (!subscribe_apps_id) {
    return
  }
  const url = `api/v1/service/jira/${subscribe_apps_id}/remove`
  return axiosIn
    .delete(url, { data: { user_id: userIds.toString() } })
    .then((res) => {
      const error = get(res.data, 'error', '')
      const statusCode = get(res.data, 'statusCode', '')
      if (error && statusCode === STATUS_CODE.BAD_REQUEST) {
        return handleError(error)
      }
      return res.data
    })
    .catch((err) => {
      return err
    })
}

function handleError(message) {
  const result = {
    success: false,
    message: '',
  }
  if (message.indexOf(MESSAGES.SAAS_CONNECT.JR01) !== -1) {
    result.message = MESSAGES.SAAS_CONNECT.LK11
  }
  return result
}

export const jiraService = {
  getDialogState,
  getCloudId,
  fetchListUser,
  addUser,
  deleteUser,
}
