<template>
  <Layout>
    <saas-layout
      v-if="dataReady"
      :page-titles="pageTitle"
      :bread-crumb-items="breadcrumbItems"
      :connected="connected"
    >
      <template #connection-info>
        <saas-information
          :src="service.logo"
          :connected="connected"
          :usage="service.category_name"
          :connect-func="activeConnection"
          :disconnect-func="showDisconnectConnection"
          :connected-date="connectedDate"
          :connected-total="usedApp"
        />
        <jira-connection-modal
          :logo="service.logo"
          :dialog-id="dialogConnectionId"
          :buttons="buttons"
          :form-data.sync="formData"
          :trigger-validate="triggerValidate"
          :is-valid.sync="isValid"
        />
      </template>
      <template #connected-content>
        <div class="SaaSConnection">
          <div class="SaaSConnection__select-time">
            <select-time :month.sync="month" :year.sync="year" />
          </div>
          <div class="SaaSConnection__list-account">
            <list-account
              :dialog-state="dialogState"
              :reload-table.sync="reloadListAccountTable"
              :date="selectedDate"
              :fetch-data="fetchListUser"
              :show-add-saas-account-dialog="showCreateDialog"
              :show-delete-dialog="showRemoveDialog"
            />
          </div>
          <div class="SaaSConnection__contract-info">
            <contract-info :selected-date="selectedDate" />
          </div>
          <div class="SaaSConnection__expense-info">
            <expense-infomation-table
              :refresh="refreshContractTable"
              :selected-date="selectedDate"
            />
          </div>
          <saas-service-modal
            :dialog-id="dialogCreateId"
            :is-remove="false"
            :is-create="true"
            :fields="dialogAddFields"
            :service-name="service.name"
            :dialog-buttons="dialogCreateButtons"
            :reset-flag="resetCreateFlag"
            :reload-func="reloadAdd"
            :checked-values.sync="checkedValues"
            :check-all.sync="checkedAllAddUser"
            :search-value.sync="searchInputAdd"
          />
          <saas-service-modal
            :dialog-id="dialogRemoveId"
            :is-remove="true"
            :fields="dialogRemoveFields"
            :service-name="service.name"
            :dialog-buttons="dialogRemoveButtons"
            :reset-flag="resetRemoveFlag"
            :reload-func="reloadRemove"
            :checked-values.sync="checkedValues"
            :check-all.sync="checkedAllRemoveUser"
            :search-value.sync="searchInputRemove"
          />
          <disconnect-confirmation
            :id="disconnectDialogId"
            :confirm-disconnect-func="deactivateConnection"
          />
        </div>
      </template>
    </saas-layout>
  </Layout>
</template>
<script>
import Layout from '@/layouts/main/app'
import SaasLayout from '@/components/commons/common-saas-layout'
import SaasInformation from '@/components/saas/saas-connection-info'
import SaasServiceModal from '@/components/modals/saas-add-remove-modal'
import ListAccount from '@/components/tables/list-of-account-using-service-table'
import SelectTime from '@/components/saas/select-time'
import ContractInfo from '@/components/tables/contract-information-table'
import ExpenseInfomationTable from '@/components/tables/expense-infomation-table'
import DisconnectConfirmation from '@/components/modals/saas-disconnect-confirm-modal'
import { ALERT_TYPE } from '@/utils/constants'
import { jiraService } from '@/services/saas-service/jira-service'
import { saasCommonService } from '@/services/saas-service/saas-common-service'
import JiraConnectionModal from '@/components/modals/jira-connection-modal'
import { mapActions, mapGetters } from 'vuex'
import { MESSAGES } from '@/utils/messages'
import { stringToDateJapan } from '@/utils/date-utils'
import { convertStringToBase64 } from '@/utils/function-utils'

export default {
  name: 'Jira',
  components: {
    JiraConnectionModal,
    Layout,
    SaasLayout,
    SaasInformation,
    SaasServiceModal,
    ListAccount,
    SelectTime,
    ContractInfo,
    ExpenseInfomationTable,
    DisconnectConfirmation,
  },
  data() {
    return {
      pageTitle: 'JIRA',
      serviceName: 'JIRA',
      breadcrumbItems: [
        {
          text: 'SaaS管理',
          href: '/customer/saas-management',
        },
        {
          text: '利用中のサービス',
          href: '/customer/saas-management',
        },
        {
          text: 'JIRA',
          active: true,
        },
      ],
      service: {},
      dialogConnectionId: 'dialog-connection-id',
      dialogCreateId: 'dialog-create',
      dialogRemoveId: 'dialog-remove',
      disconnectDialogId: 'dialog-disconnect-confirm',
      connected: false,
      dialogAddFields: saasCommonService.getSaaSAddModalFields(),
      dialogRemoveFields: saasCommonService.getSaaSRemoveModalFields(),
      resetCreateFlag: false,
      resetRemoveFlag: false,
      month: '',
      year: '',
      dialogState: jiraService.getDialogState(),
      buttons: [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: '連携',
          variant: 'primary',
          minWidth: 133,
          size: 'md',
          func: this.handleConnectJira,
        },
      ],
      formData: {},
      triggerValidate: false,
      isValid: false,
      serviceId: '',
      dataReady: false,
      reloadAdd: saasCommonService.getListIntegrateUsers,
      reloadRemove: saasCommonService.getListUserBySaas,
      checkedValues: [],
      reloadListAccountTable: false,
      checkedAllAddUser: false,
      searchInputAdd: '',
      checkedAllRemoveUser: false,
      searchInputRemove: '',
      refreshContractTable: false,
    }
  },
  computed: {
    ...mapGetters('commonSettings', ['apps']),
    ...mapGetters('saas', ['code']),
    connectedDate() {
      return stringToDateJapan(this.service.connect_time)
    },
    selectedDate() {
      if (this.month && this.year) {
        return `${this.month}-${this.year}`
      }
      return ''
    },
    dialogCreateButtons() {
      return [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: '保存',
          variant: 'primary',
          minWidth: 124,
          size: 'md',
          func: this.onSubmitAddDialog,
        },
      ]
    },
    dialogRemoveButtons() {
      return [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: '削除',
          variant: 'primary',
          minWidth: 124,
          size: 'md',
          func: this.onSubmitRemoveDialog,
        },
      ]
    },
    userIds() {
      return this.checkedValues.map((item) => item.id)
    },
    usedApp() {
      return this.$store.getters['saas/service'].member || 0
    },
  },
  async created() {
    this.serviceId = Number(this.$route.fullPath.split('/').pop())
    this.service = await saasCommonService.getDetailSaas(this.serviceId)
    this.setService(this.service)
    this.savedMetadata = await saasCommonService.getSavedMetadata(this.serviceId)
    this.setServiceMetadata(this.savedMetadata)
    this.connected = this.service.connect_time && this.service.is_active
    if (!this.connected) {
      this.resetFetchTime(this.serviceId)
    }
    this.dataReady = true
    if (!this.service) {
      await this.$router.push('/404')
    }
  },
  methods: {
    ...mapActions('saas', ['setService', 'setServiceMetadata', 'resetFetchTime']),
    ...mapActions('alert', ['displayAlert']),
    ...mapActions('loading', ['showManualLoading', 'hideManualLoading', 'enableLoading']),
    activeConnection() {
      this.$bvModal.show(this.dialogConnectionId)
    },
    showDisconnectConnection() {
      this.$bvModal.show(this.disconnectDialogId)
    },
    async deactivateConnection() {
      // call api,auth
      const { success, message } = await saasCommonService.disconnectApp(this.service.id)
      if (!success) {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: message,
        })
        return
      }
      this.connected = false
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.SAAS_CONNECT.LK03,
      })
    },
    showCreateDialog() {
      this.resetCreateFlag = !this.resetCreateFlag
      this.$bvModal.show(this.dialogCreateId)
    },
    showRemoveDialog() {
      this.resetRemoveFlag = !this.resetRemoveFlag
      this.$bvModal.show(this.dialogRemoveId)
    },
    async onSubmitAddDialog() {
      if (this.userIds.length === 0) {
        return
      }
      let ids = this.userIds
      if (this.checkedAllAddUser) {
        const { items } = await saasCommonService.getListIntegrateUsers(
          this.service.subscribe_apps_id,
          null,
          null,
          null,
          this.searchInputAdd
        )
        ids = items.map((item) => item.id)
      }
      // call api add
      const result = await jiraService.addUser(this.service.subscribe_apps_id, ids)
      if (result) {
        this.displayAlert({
          type: ALERT_TYPE.SUCCESS,
          messages: MESSAGES.COMMON.MSG01,
        })
      }
      this.reloadListAccountTable = !this.reloadListAccountTable
      this.$bvModal.hide(this.dialogCreateId)
    },
    async onSubmitRemoveDialog() {
      if (this.userIds.length === 0) {
        return
      }
      let ids = this.userIds
      if (this.checkedAllRemoveUser) {
        const { items } = await saasCommonService.getListUserBySaas(
          this.serviceId,
          null,
          null,
          null,
          null,
          this.searchInputRemove
        )
        ids = items.map((item) => item.id)
      }
      // call api remove

      const { success, message } = await jiraService.deleteUser(this.service.subscribe_apps_id, ids)
      if (!success) {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: message,
        })
        this.$bvModal.hide(this.dialogRemoveId)
        return
      }
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.COMMON.MSG05,
      })
      this.reloadListAccountTable = !this.reloadListAccountTable
      this.$bvModal.hide(this.dialogRemoveId)
    },
    async handleConnectJira() {
      const data = { ...this.formData }
      this.triggerValidate = !this.triggerValidate
      setTimeout(async () => {
        if (this.isValid) {
          this.enableLoading()
          this.showManualLoading()
          try {
            const cloudId = await jiraService.getCloudId(data.domain)
            if (!cloudId) {
              this.displayAlert({
                type: ALERT_TYPE.ERROR,
                messages: MESSAGES.SAAS_CONNECT.LK10,
              })
              this.hideManualLoading()
              return
            }
            const apiToken = convertStringToBase64(data.email + ':' + data.token)
            if (cloudId && apiToken) {
              const res = await saasCommonService.connectApp(this.serviceId)
              if (!res.success) {
                this.displayAlert({
                  type: ALERT_TYPE.ERROR,
                  messages: res.messages || '',
                })
                return
              }
              const metaData = {
                access_token: apiToken,
                instance_url: process.env.VUE_APP_JIRA_INSTANCE_URL,
                token_type: 'Basic',
                auth_type: 'Authorization',
                cloud_id: cloudId,
              }
              await saasCommonService.addToken(this.service.id, metaData, true)
              this.service = await saasCommonService.getDetailSaas(this.service.id, true)
              this.setService(this.service)
              this.savedMetadata = await saasCommonService.getSavedMetadata(this.service.id, true)
              this.setServiceMetadata(this.savedMetadata)
              this.connected = true
              this.$bvModal.hide(this.dialogConnectionId)
              this.displayAlert({
                type: ALERT_TYPE.SUCCESS,
                messages: MESSAGES.SAAS_CONNECT.LK01,
              })
              this.formData = {}
            } else {
              this.displayAlert({
                type: ALERT_TYPE.ERROR,
                messages: MESSAGES.COMMON.MSG15,
              })
            }
            this.hideManualLoading()
          } catch (e) {
            this.hideManualLoading()
          } finally {
            this.hideManualLoading()
          }
        }
      }, 150)
    },

    async fetchListUser() {
      const { success, message } = await jiraService.fetchListUser(this.service.subscribe_apps_id)
      if (!success) {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: message,
        })
        return
      }
      this.refreshContractTable = !this.refreshContractTable
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.SAAS_CONNECT.LK04,
      })
    },
  },
}
</script>
